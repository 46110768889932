<template>
  <div>
       <!-- 创建 -->
        <div style="max-width:500px;min-width:200px;margin:0 auto;background:#fff;">
            <div class="platform">
                <div class="items" v-for="(item,key) in listData" :key="key" @click="getAuth(item)">
                    <img :src="item.icon" alt="" >
                </div>
            </div>

            <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;margin-top:60px;">
                <!-- <el-button type="primary"  @click="exit();">关 闭</el-button> -->
            </div>
        </div> 
  </div>
</template>

<script>
import tools from "../../../extends/tools";
import apis from "../../common/js/api2";

export default {
    props:{
        id:{
            type:Number,
            default:0
        }
    },
    data(){
        return {
            saveData:{
                
            },
            listData:[],
            format:'YYYY-MM-DD HH:mm:ss'
        }
    },
    methods:{
        getAuth(item){
            location.href = item.request_address;
        },
        beforeUpload(){
            this.uploadLoading = true;
        },
        //插入图片
        pickPicture(file) {
            this.uploadLoading = false;
            this.saveData.portrait = file.font;
            this.imageUrl = this.baseUrl+file.font;
        },
        save(){

        },
        exit(){
            this.$emit('shareExit')
        },
        fleshList(){
            this.$emit('fleshList')
        }
    },
    created(){
        //获取电商平台列表
        apis.getShopPlatform().then((res)=>{
            this.listData = res.data;
        }).catch(error=>{
            console.log(error);
            this.$message('网络或程序错误，请稍候重试！');
        });
    }
}
</script>

<style lang="less">
    .platform{
        display: flex;justify-content:center;
        .items{
            width:60px;height:60px;margin:10px 0 0 20px;cursor: pointer;
            img{
                width:100%;
            }
        }
    }
</style>