<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" >
        <div class="sale" >
            <!-- 顶部导航 -->
            <el-card class="box-card">
                <!-- 按钮 -->
                <el-row style="height:40px;">
                    <el-button type="primary" @click="addStoreFlag=true" class="buttons">新增网店</el-button>
                    <!-- <el-button type="primary" @click="createShareFlag=true" class="buttons">新增门店</el-button> -->
                </el-row>

                <!-- 角色2网店选择列表 -->
                <div class="shopList" >
                    <div class="item"  v-for="(item,key) in tableData" :key="key" 
                    @mouseover="activeId=item.id" 
                    @mouseout="activeId=0" 
                    :class="{focus:item.isActive}">
                        <!-- 左边蓝色条 -->
                        <div class="left_bar"></div>
                        <div class="shop_name">{{item.name}}</div>
                        <!-- <div class="validate_time">有效期至：2022.2.22</div> -->
                        <!-- <div class="enter" v-show="activeId == item.id">进入此门店</div> -->
                        <div class="button">编辑</div>
                    </div>
                </div>

                <!-- 页码 -->
                <div class="block">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="req.page"
                            :page-size="req.limit"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                            class="flex-right">
                    </el-pagination>
                </div>
            </el-card>
        </div>

        <!-- 创建分享 -->
        <el-dialog
        :modal-append-to-body="false"
        title="添加网店"
        :visible.sync="createShareFlag"
        v-if="createShareFlag"
        width="60%"
        center>
            <createShare  :id="currentId" @fleshList="getList" @shareExit="shareExit"></createShare>
        </el-dialog>

        <el-dialog
        :modal-append-to-body="false"
        title="绑定网店"
        :visible.sync="addStoreFlag"
        v-if="addStoreFlag"
        width="60%"
        center>
            <addStore  :id="currentId" @fleshList="getList" @shareExit="shareExit"></addStore>
        </el-dialog>

        <!-- 分享列表 -->
        <el-dialog
        :modal-append-to-body="false"
        title="分享列表"
        :visible.sync="shareListFlag"
        v-if="shareListFlag"
        width="80%"
        center>
            <add-store :id="currentId" ></add-store>
        </el-dialog>
    </div>
</template>

<script>
import createShare from './creatDialog';
import addStore from './addStore';
import shareListFlag from './listDialog';
import apis from "../../common/js/api2";
export default {
props:{

    },
    inject: ['selectSide'], 
    data(){
        return {
            //上传图片列表地址
            uploadImages: [

            ],
            createShareFlag:false,
            addStoreFlag:false,
            shareListFlag:false,
            currentId:0,
            tableData:[],
            total:0,
            currentPage:1,
            shareId:0,
            statusList:[],
            activeId:0,
            //角色二
            userinfo:JSON.parse(localStorage.getItem('userinfo')),
            req:{
                limit:30,
                page:1,
                keywords:''
            }
        }
    },
    components:{
        createShare,shareListFlag,addStore
    },
    methods:{
        onSubmit(){

        },
        handleSizeChange(val) {
            this.req.limit = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.req.page = val;
            this.getList();
        },
        getList(){
            apis.shopListRole2(this.req).then((res) => {
                this.tableData = res.data.data;
                this.total = res.data.total;
            }).catch((err) => {
                this.$message('网络或程序错误，请稍候重试！', 'error');
            });
        },
        exit(){
            this.$emit('shareExit')
        },
        shareExit(){
            this.createShareFlag = false;
            this.shareListFlag = false;
        },
        remove(id, key) {
            this.$confirm('确定删除此销售单?', '删除确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                apis.shopDelete({ id: id}).then((info) => {
                    if (info.code == 200) {
                        this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: info.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        edit(id){
            this.createShareFlag = true;
            this.currentId = id;
        }
    },
    created(){
        console.log(this.userinfo);
        console.log(apis);
        this.getList();
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
@import url('../less/shop');
</style>